


































































































































































































































































































































































































import {Vue, Component} from "vue-property-decorator";
import QuestListItem from "@/components/QuestListItem.vue";

@Component({
  name: "NewQuestResponsive",
  components:{ QuestListItem}
})

export default class NewQuestResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected settingsOpen = false;
  protected dropdownOpen = false;
}
